<template>
  <div id="app" class="overflow-x-hidden h-100">
    <link
      rel="stylesheet"
      href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css"
    />
    <link rel="preconnect" href="https://fonts.googleapis.com" />
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
    <link
      href="https://fonts.googleapis.com/css2?family=Oxanium:wght@200&display=swap"
      rel="stylesheet"
    />
    <template-one></template-one>
  </div>
</template>

<script>
import TemplateOne from "./components/TemplateOne.vue";

export default {
  name: "App",
  components: {
    TemplateOne,
  },
};
</script>

<style lang="scss" scoped>
#app {
  font-family: "Oxanium", cursive !important;
}
</style>
>
