<template>
  <b-row no-gutters class="h-100 template-one">
    <div class="background-container background-image-class"></div>
    <b-col
      cols="12"
      class="p-5 d-flex flex-column justify-content-center align-items-center text-container"
    >
      <div class="text-center">
        <div class="title mb-3">{{ title }}</div>
        <div class="disclaimer font-weight-light">
          <vue-typed-js class="d-block" :loop="true" :strings="disclaimer">
            <span class="typing"></span>
          </vue-typed-js>
        </div>
      </div>
      <div class="mt-auto text-center social-media-container">
        <a
          v-if="instagram"
          :href="instagram"
          target="_blank"
          class="text-body mx-2 my-3"
        >
          <span class="icon is-large">
            <i class="fab fa-instagram"></i>
          </span>
        </a>
        <a
          v-if="facebook"
          :href="facebook"
          target="_blank"
          class="text-body mx-2 my-3"
        >
          <span class="icon is-large">
            <i class="fab fa-facebook"></i>
          </span>
        </a>
        <a
          v-if="mail"
          :href="'mailto:' + mail"
          target="_blank"
          class="text-body mx-2 my-3"
        >
          <span class="icon is-large">
            <i class="fas fa-envelope"></i>
          </span>
        </a>
      </div>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "TemplateOne",
  data() {
    return {
      logo: require("@/assets/logo.png"),
      backgroundImage: require("@/assets/bg.jpg"),
      title: process.env.VUE_APP_TITLE,
      disclaimer: [
        process.env.VUE_APP_DISCLAIMER_FIRST_LINE,
        process.env.VUE_APP_DISCLAIMER_SECOND_LINE,
      ],
      facebook: process.env.VUE_APP_FACEBOOK,
      instagram: process.env.VUE_APP_INSTAGRAM,
      mail: process.env.VUE_APP_MAIL,
      linkedin: process.env.VUE_APP_LINKEDIN,
    };
  },
};
</script>

<style lang="scss" scoped>
.template-one {
  .text-container {
    position: relative;
    z-index: 1;
  }
  .title {
    padding-top: 25vh;
    font-size: 6rem;
    color: rgba(255, 255, 255, 0.58) !important;
  }
  .disclaimer {
    font-size: 1.5rem;
    color: rgba(255, 255, 255, 0.58) !important;
  }
  .background-container {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    background-image: url("~@/assets/bg.jpg");
    background-position: center center;
    background-size: cover;
    filter: grayscale(1);
  }

  .background-image-class {
    filter: brightness(0.3) grayscale(1);
  }
  .social-media-container {
    a {
      transition: all 1s;
      color: white !important;
      &:hover {
        color: #464545 !important;
        text-decoration: none;
      }
    }
  }
  .is-large {
    font-size: 1.3125em;
    line-height: 1.45;
    fill: white !important;
  }

  /* Media Queries */
  @media screen and (max-width: 767px) {
    .title {
      font-size: 4rem;
    }
    .disclaimer {
      font-size: 1.2rem;
    }
  }

  @media screen and (min-width: 768px) and (max-width: 1023px) {
    .title {
      font-size: 5rem;
    }
    .disclaimer {
      font-size: 1.4rem;
    }
  }

  @media screen and (min-width: 1024px) and (max-width: 1199px) {
    .title {
      font-size: 5.5rem;
    }
    .disclaimer {
      font-size: 1.6rem;
    }
  }

  @media screen and (min-width: 1200px) {
    .title {
      font-size: 6rem;
    }
    .disclaimer {
      font-size: 1.8rem;
    }
  }
}
</style>
